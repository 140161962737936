@import url(/../../../assets/fonts/MyFontsWebfontsKit.css);
@import url("https://fonts.googleapis.com/css?family=Antic|Lato:300,400,700&display=swap");
@import url(/../../../assets/fonts/MyFontsWebfontsKit.css);
@import url("https://fonts.googleapis.com/css?family=Antic|Lato:300,400,700&display=swap");
@import url(/../../../assets/fonts/MyFontsWebfontsKit.css);
@import url("https://fonts.googleapis.com/css?family=Antic|Lato:300,400,700&display=swap");
@import url(/../../../assets/fonts/MyFontsWebfontsKit.css);
@import url("https://fonts.googleapis.com/css?family=Antic|Lato:300,400,700&display=swap");
@import url(/../../../assets/fonts/MyFontsWebfontsKit.css);
@import url("https://fonts.googleapis.com/css?family=Antic|Lato:300,400,700&display=swap");
:export {
  fullHeaderFont: 400 13px "GalanoGrotesque-SemiBold", serif;
  tertiaryFont: "CoreSansA65Bold"; }

:export {
  secondaryBackgroundColor: #f3f3f3;
  alternateBackgroundColor: #101010;
  headerBackgroundColor: #fdfeff;
  primaryLinkColor: #215fb5;
  footerBackgroundColor: #212121;
  linkTileActiveBorderUnderline: 1px solid #215fb5;
  headerLinkTileActiveColor: #215fb5;
  linkTileHoverUnderline: 0;
  linkTileBackgroundColor: transparent;
  headerLinkTileColor: #383838;
  headerLinkTileHoverColor: #1f1f1f; }

:export {
  fullHeaderFont: 400 13px "GalanoGrotesque-SemiBold", serif;
  tertiaryFont: "CoreSansA65Bold"; }

:export {
  secondaryBackgroundColor: #f3f3f3;
  alternateBackgroundColor: #101010;
  headerBackgroundColor: #fdfeff;
  primaryLinkColor: #215fb5;
  footerBackgroundColor: #212121;
  linkTileActiveBorderUnderline: 1px solid #215fb5;
  headerLinkTileActiveColor: #215fb5;
  linkTileHoverUnderline: 0;
  linkTileBackgroundColor: transparent;
  headerLinkTileColor: #383838;
  headerLinkTileHoverColor: #1f1f1f; }

.bodyFlexContainer {
  display: flex;
  justify-content: center;
  width: 1310px;
  margin: auto; }
  .bodyFlexContainer .tilesFlex {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap; }
    .bodyFlexContainer .tilesFlex > div {
      width: 190px;
      margin: 10px;
      padding: 10px;
      background-color: #f9f9f9;
      text-align: center;
      font: 300 14px Lato, serif; }

@media screen and (max-width: 450px) {
  html body #root {
    position: relative;
    top: 0; }
    html body #root .content .header {
      justify-content: flex-start;
      position: fixed;
      right: 0;
      left: 0;
      top: 0;
      z-index: 11; }
      html body #root .content .header .headerContent {
        position: relative;
        left: 0; } }

@media screen and (max-width: 1310px) {
  .bodyFlexContainer {
    width: 80%; } }

:export {
  websitePageWidth: 1310px;
  headerOffset: 0;
  mobileMenuWidth: 210px; }

:export {
  secondaryBackgroundColor: #f3f3f3;
  alternateBackgroundColor: #101010;
  headerBackgroundColor: #fdfeff;
  primaryLinkColor: #215fb5;
  footerBackgroundColor: #212121;
  linkTileActiveBorderUnderline: 1px solid #215fb5;
  headerLinkTileActiveColor: #215fb5;
  linkTileHoverUnderline: 0;
  linkTileBackgroundColor: transparent;
  headerLinkTileColor: #383838;
  headerLinkTileHoverColor: #1f1f1f; }

:export {
  fullHeaderFont: 400 13px "GalanoGrotesque-SemiBold", serif;
  tertiaryFont: "CoreSansA65Bold"; }

:export {
  secondaryBackgroundColor: #f3f3f3;
  alternateBackgroundColor: #101010;
  headerBackgroundColor: #fdfeff;
  primaryLinkColor: #215fb5;
  footerBackgroundColor: #212121;
  linkTileActiveBorderUnderline: 1px solid #215fb5;
  headerLinkTileActiveColor: #215fb5;
  linkTileHoverUnderline: 0;
  linkTileBackgroundColor: transparent;
  headerLinkTileColor: #383838;
  headerLinkTileHoverColor: #1f1f1f; }

.bodyFlexContainer {
  display: flex;
  justify-content: center;
  width: 1310px;
  margin: auto; }
  .bodyFlexContainer .tilesFlex {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap; }
    .bodyFlexContainer .tilesFlex > div {
      width: 190px;
      margin: 10px;
      padding: 10px;
      background-color: #f9f9f9;
      text-align: center;
      font: 300 14px Lato, serif; }

@media screen and (max-width: 450px) {
  html body #root {
    position: relative;
    top: 0; }
    html body #root .content .header {
      justify-content: flex-start;
      position: fixed;
      right: 0;
      left: 0;
      top: 0;
      z-index: 11; }
      html body #root .content .header .headerContent {
        position: relative;
        left: 0; } }

@media screen and (max-width: 1310px) {
  .bodyFlexContainer {
    width: 80%; } }

:export {
  websitePageWidth: 1310px;
  headerOffset: 0;
  mobileMenuWidth: 210px; }

:export {
  secondaryBackgroundColor: #f3f3f3;
  alternateBackgroundColor: #101010;
  headerBackgroundColor: #fdfeff;
  primaryLinkColor: #215fb5;
  footerBackgroundColor: #212121;
  linkTileActiveBorderUnderline: 1px solid #215fb5;
  headerLinkTileActiveColor: #215fb5;
  linkTileHoverUnderline: 0;
  linkTileBackgroundColor: transparent;
  headerLinkTileColor: #383838;
  headerLinkTileHoverColor: #1f1f1f; }

:export {
  fullHeaderFont: 400 13px "GalanoGrotesque-SemiBold", serif;
  tertiaryFont: "CoreSansA65Bold"; }

:export {
  secondaryBackgroundColor: #f3f3f3;
  alternateBackgroundColor: #101010;
  headerBackgroundColor: #fdfeff;
  primaryLinkColor: #215fb5;
  footerBackgroundColor: #212121;
  linkTileActiveBorderUnderline: 1px solid #215fb5;
  headerLinkTileActiveColor: #215fb5;
  linkTileHoverUnderline: 0;
  linkTileBackgroundColor: transparent;
  headerLinkTileColor: #383838;
  headerLinkTileHoverColor: #1f1f1f; }

.bodyFlexContainer {
  display: flex;
  justify-content: center;
  width: 1310px;
  margin: auto; }
  .bodyFlexContainer .tilesFlex {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap; }
    .bodyFlexContainer .tilesFlex > div {
      width: 190px;
      margin: 10px;
      padding: 10px;
      background-color: #f9f9f9;
      text-align: center;
      font: 300 14px Lato, serif; }

@media screen and (max-width: 450px) {
  html body #root {
    position: relative;
    top: 0; }
    html body #root .content .header {
      justify-content: flex-start;
      position: fixed;
      right: 0;
      left: 0;
      top: 0;
      z-index: 11; }
      html body #root .content .header .headerContent {
        position: relative;
        left: 0; } }

@media screen and (max-width: 1310px) {
  .bodyFlexContainer {
    width: 80%; } }

:export {
  websitePageWidth: 1310px;
  headerOffset: 0;
  mobileMenuWidth: 210px; }

:export {
  fullHeaderFont: 400 13px "GalanoGrotesque-SemiBold", serif;
  tertiaryFont: "CoreSansA65Bold"; }

:export {
  secondaryBackgroundColor: #f3f3f3;
  alternateBackgroundColor: #101010;
  headerBackgroundColor: #fdfeff;
  primaryLinkColor: #215fb5;
  footerBackgroundColor: #212121;
  linkTileActiveBorderUnderline: 1px solid #215fb5;
  headerLinkTileActiveColor: #215fb5;
  linkTileHoverUnderline: 0;
  linkTileBackgroundColor: transparent;
  headerLinkTileColor: #383838;
  headerLinkTileHoverColor: #1f1f1f; }

.bodyFlexContainer {
  display: flex;
  justify-content: center;
  width: 1310px;
  margin: auto; }
  .bodyFlexContainer .tilesFlex {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap; }
    .bodyFlexContainer .tilesFlex > div {
      width: 190px;
      margin: 10px;
      padding: 10px;
      background-color: #f9f9f9;
      text-align: center;
      font: 300 14px Lato, serif; }

@media screen and (max-width: 450px) {
  html body #root {
    position: relative;
    top: 0; }
    html body #root .content .header {
      justify-content: flex-start;
      position: fixed;
      right: 0;
      left: 0;
      top: 0;
      z-index: 11; }
      html body #root .content .header .headerContent {
        position: relative;
        left: 0; } }

@media screen and (max-width: 1310px) {
  .bodyFlexContainer {
    width: 80%; } }

:export {
  websitePageWidth: 1310px;
  headerOffset: 0;
  mobileMenuWidth: 210px; }

:export {
  secondaryBackgroundColor: #f3f3f3;
  alternateBackgroundColor: #101010;
  headerBackgroundColor: #fdfeff;
  primaryLinkColor: #215fb5;
  footerBackgroundColor: #212121;
  linkTileActiveBorderUnderline: 1px solid #215fb5;
  headerLinkTileActiveColor: #215fb5;
  linkTileHoverUnderline: 0;
  linkTileBackgroundColor: transparent;
  headerLinkTileColor: #383838;
  headerLinkTileHoverColor: #1f1f1f; }

:export {
  secondaryBackgroundColor: #f3f3f3;
  alternateBackgroundColor: #101010;
  headerBackgroundColor: #fdfeff;
  primaryLinkColor: #215fb5;
  footerBackgroundColor: #212121;
  linkTileActiveBorderUnderline: 1px solid #215fb5;
  headerLinkTileActiveColor: #215fb5;
  linkTileHoverUnderline: 0;
  linkTileBackgroundColor: transparent;
  headerLinkTileColor: #383838;
  headerLinkTileHoverColor: #1f1f1f; }

.gridTiles {
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  grid-gap: 40px;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  padding: 40px;
  grid-auto-flow: row dense;
  justify-items: center;
  justify-content: space-between; }
  .gridTiles div {
    text-align: center;
    width: 100%; }
    .gridTiles div img {
      width: 100%; }
    .gridTiles div figure {
      width: 100%;
      margin: 0;
      padding: 0; }
  @media (max-width: 1852px) {
    .gridTiles > div:nth-child(5) {
      grid-column: 1/3;
      grid-row: 2; }
    .gridTiles > div:nth-child(6) {
      grid-column: 3/5;
      grid-row: 2; } }
  @media (max-width: 1252px) {
    .gridTiles > div:nth-child(3) {
      grid-column: 1/2;
      grid-row: 2; }
    .gridTiles > div:nth-child(4) {
      grid-column: 2/3;
      grid-row: 2; }
    .gridTiles > div:nth-child(5) {
      grid-column: 1/2;
      grid-row: 3; }
    .gridTiles > div:nth-child(6) {
      grid-column: 2/3;
      grid-row: 3; } }
  @media (max-width: 652px) {
    .gridTiles {
      grid-template-columns: 1fr; }
      .gridTiles > div {
        grid-column: 1/2 !important; }
      .gridTiles > div:nth-child(3) {
        grid-row: 3; }
      .gridTiles > div:nth-child(4) {
        grid-row: 4; }
      .gridTiles > div:nth-child(5) {
        grid-row: 5; }
      .gridTiles > div:nth-child(6) {
        grid-row: 6; } }
  @media (max-width: 768px) {
    .gridTiles {
      padding: 15px;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr)); } }
  @media (max-width: 450px) {
    .gridTiles {
      padding: 10px 20px; } }

.gridTiles-t {
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: flex-start;
  align-items: flex-start;
  grid-gap: 40px;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  padding: 40px;
  grid-auto-flow: row dense;
  justify-items: center;
  justify-content: space-between; }
  .gridTiles-t div {
    text-align: center;
    width: 100%; }
    .gridTiles-t div img {
      width: 100%; }
    .gridTiles-t div figure {
      width: 100%;
      margin: 0;
      padding: 0; }
  @media (max-width: 1852px) {
    .gridTiles-t > div:nth-child(5) {
      grid-column: 1/3;
      grid-row: 2; }
    .gridTiles-t > div:nth-child(6) {
      grid-column: 3/5;
      grid-row: 2; } }
  @media (max-width: 1252px) {
    .gridTiles-t > div:nth-child(3) {
      grid-column: 1/2;
      grid-row: 2; }
    .gridTiles-t > div:nth-child(4) {
      grid-column: 2/3;
      grid-row: 2; }
    .gridTiles-t > div:nth-child(5) {
      grid-column: 1/2;
      grid-row: 3; }
    .gridTiles-t > div:nth-child(6) {
      grid-column: 2/3;
      grid-row: 3; } }
  @media (max-width: 652px) {
    .gridTiles-t {
      grid-template-columns: 1fr; }
      .gridTiles-t > div {
        grid-column: 1/2 !important; }
      .gridTiles-t > div:nth-child(3) {
        grid-row: 3; }
      .gridTiles-t > div:nth-child(4) {
        grid-row: 4; }
      .gridTiles-t > div:nth-child(5) {
        grid-row: 5; }
      .gridTiles-t > div:nth-child(6) {
        grid-row: 6; } }
  @media (max-width: 768px) {
    .gridTiles-t {
      padding: 15px;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr)); } }
  @media (max-width: 450px) {
    .gridTiles-t {
      padding: 10px 20px; } }

.gridTilesSmall-t {
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: flex-start;
  align-items: flex-start;
  grid-gap: 40px;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  padding: 40px;
  grid-auto-flow: row dense;
  justify-items: center;
  justify-content: space-between; }
  .gridTilesSmall-t div {
    text-align: center;
    width: 100%; }
    .gridTilesSmall-t div img {
      width: 100%; }
    .gridTilesSmall-t div figure {
      width: 100%;
      margin: 0;
      padding: 0; }
  @media (max-width: 1252px) {
    .gridTilesSmall-t > div:nth-child(5) {
      grid-column: 1/3;
      grid-row: 2; }
    .gridTilesSmall-t > div:nth-child(6) {
      grid-column: 3/5;
      grid-row: 2; } }
  @media (max-width: 852px) {
    .gridTilesSmall-t > div:nth-child(3) {
      grid-column: 1/2;
      grid-row: 2; }
    .gridTilesSmall-t > div:nth-child(4) {
      grid-column: 2/3;
      grid-row: 2; }
    .gridTilesSmall-t > div:nth-child(5) {
      grid-column: 1/2;
      grid-row: 3; }
    .gridTilesSmall-t > div:nth-child(6) {
      grid-column: 2/3;
      grid-row: 3; } }
  @media (max-width: 452px) {
    .gridTilesSmall-t {
      grid-template-columns: 1fr; }
      .gridTilesSmall-t > div {
        grid-column: 1/2 !important; }
      .gridTilesSmall-t > div:nth-child(3) {
        grid-row: 3; }
      .gridTilesSmall-t > div:nth-child(4) {
        grid-row: 4; }
      .gridTilesSmall-t > div:nth-child(5) {
        grid-row: 5; }
      .gridTilesSmall-t > div:nth-child(6) {
        grid-row: 6; } }
  @media (max-width: 768px) {
    .gridTilesSmall-t {
      padding: 15px;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(160px, 1fr)); } }
  @media (max-width: 450px) {
    .gridTilesSmall-t {
      padding: 10px 20px; } }

.gridTilesLarge {
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  padding: 10px;
  grid-auto-flow: row dense;
  justify-items: center;
  justify-content: space-between; }
  .gridTilesLarge div {
    text-align: center;
    width: 100%; }
    .gridTilesLarge div img {
      width: 100%; }
    .gridTilesLarge div figure {
      width: 100%;
      margin: 0;
      padding: 0; }
  @media (max-width: 2412px) {
    .gridTilesLarge > div:nth-child(5) {
      grid-column: 1/3;
      grid-row: 2; }
    .gridTilesLarge > div:nth-child(6) {
      grid-column: 3/5;
      grid-row: 2; } }
  @media (max-width: 1612px) {
    .gridTilesLarge > div:nth-child(3) {
      grid-column: 1/2;
      grid-row: 2; }
    .gridTilesLarge > div:nth-child(4) {
      grid-column: 2/3;
      grid-row: 2; }
    .gridTilesLarge > div:nth-child(5) {
      grid-column: 1/2;
      grid-row: 3; }
    .gridTilesLarge > div:nth-child(6) {
      grid-column: 2/3;
      grid-row: 3; } }
  @media (max-width: 812px) {
    .gridTilesLarge {
      grid-template-columns: 1fr; }
      .gridTilesLarge > div {
        grid-column: 1/2 !important; }
      .gridTilesLarge > div:nth-child(3) {
        grid-row: 3; }
      .gridTilesLarge > div:nth-child(4) {
        grid-row: 4; }
      .gridTilesLarge > div:nth-child(5) {
        grid-row: 5; }
      .gridTilesLarge > div:nth-child(6) {
        grid-row: 6; } }
  @media (max-width: 768px) {
    .gridTilesLarge {
      padding: 15px;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(380px, 1fr)); } }
  @media (max-width: 450px) {
    .gridTilesLarge {
      padding: 10px 20px; } }
  @media (max-width: 768px) {
    .gridTilesLarge {
      padding: 10px;
      grid-template-columns: 1fr; }
      .gridTilesLarge > div {
        grid-column: 1/2; } }

.gridTilesArticle {
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: left;
  align-items: left;
  grid-gap: 40px;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  padding: 40px;
  grid-auto-flow: row dense;
  justify-items: center;
  justify-content: space-between; }
  .gridTilesArticle div {
    text-align: center;
    width: 100%; }
    .gridTilesArticle div img {
      width: 100%; }
    .gridTilesArticle div figure {
      width: 100%;
      margin: 0;
      padding: 0; }
  @media (max-width: 1852px) {
    .gridTilesArticle > div:nth-child(5) {
      grid-column: 1/3;
      grid-row: 2; }
    .gridTilesArticle > div:nth-child(6) {
      grid-column: 3/5;
      grid-row: 2; } }
  @media (max-width: 1252px) {
    .gridTilesArticle > div:nth-child(3) {
      grid-column: 1/2;
      grid-row: 2; }
    .gridTilesArticle > div:nth-child(4) {
      grid-column: 2/3;
      grid-row: 2; }
    .gridTilesArticle > div:nth-child(5) {
      grid-column: 1/2;
      grid-row: 3; }
    .gridTilesArticle > div:nth-child(6) {
      grid-column: 2/3;
      grid-row: 3; } }
  @media (max-width: 652px) {
    .gridTilesArticle {
      grid-template-columns: 1fr; }
      .gridTilesArticle > div {
        grid-column: 1/2 !important; }
      .gridTilesArticle > div:nth-child(3) {
        grid-row: 3; }
      .gridTilesArticle > div:nth-child(4) {
        grid-row: 4; }
      .gridTilesArticle > div:nth-child(5) {
        grid-row: 5; }
      .gridTilesArticle > div:nth-child(6) {
        grid-row: 6; } }
  @media (max-width: 768px) {
    .gridTilesArticle {
      padding: 15px;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr)); } }
  @media (max-width: 450px) {
    .gridTilesArticle {
      padding: 10px 20px; } }
  .gridTilesArticle div {
    text-align: left;
    width: 100%;
    font-size: 16px;
    line-height: 1.5rem; }
    .gridTilesArticle div img {
      width: 100%; }
    .gridTilesArticle div h1, .gridTilesArticle div h2, .gridTilesArticle div h3, .gridTilesArticle div h4, .gridTilesArticle div h5, .gridTilesArticle div h6 {
      font-size: 20px; }

.iconTitleTextContainer {
  display: flex;
  justify-content: space-evenly; }
  .iconTitleTextContainer .tile {
    display: flex;
    flex-direction: column;
    padding: 32px; }
    .iconTitleTextContainer .tile img.circlePic {
      border-radius: 50%; }
    .iconTitleTextContainer .tile > div {
      flex-wrap: wrap;
      max-width: 350px;
      text-align: center; }
      .iconTitleTextContainer .tile > div a {
        color: #215fb5;
        text-decoration: none; }
        .iconTitleTextContainer .tile > div a:visited {
          color: #215fb5; }
        .iconTitleTextContainer .tile > div a:hover {
          color: #5b93e1;
          text-decoration: none; }
        .iconTitleTextContainer .tile > div a:active {
          color: #5b93e1; }

@media (max-width: 768px) {
  .iconTitleTextContainer {
    flex-direction: column; }
    .iconTitleTextContainer .tile div {
      max-width: 100% !important; } }

html {
  height: 100%; }
  html body {
    height: 100%;
    overflow-x: hidden;
    font-family: "CoreSansA45Regular";
    color: #000000;
    font-size: 14px;
    text-align: left; }
    html body.dark-mode {
      background-color: #373737 !important; }
    html body #root {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column; }
    html body .alternateBackground {
      background-color: #101010;
      padding: 20px 0; }
    html body .content {
      flex: 1 0 auto; }
      html body .content h1.tinyTextTitle, html body .content h2.tinyTextTitle, html body .content h3.tinyTextTitle, html body .content h4.tinyTextTitle, html body .content h5.tinyTextTitle, html body .content h6.tinyTextTitle {
        font-family: "CoreSansA45Regular";
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0;
        font-weight: 400;
        color: #505050;
        text-align: center; }
        html body .content h1.tinyTextTitle a, html body .content h2.tinyTextTitle a, html body .content h3.tinyTextTitle a, html body .content h4.tinyTextTitle a, html body .content h5.tinyTextTitle a, html body .content h6.tinyTextTitle a {
          text-decoration: none;
          color: #215fb5; }
      html body .content h1.tinyTextTitle-a, html body .content h2.tinyTextTitle-a, html body .content h3.tinyTextTitle-a, html body .content h4.tinyTextTitle-a, html body .content h5.tinyTextTitle-a, html body .content h6.tinyTextTitle-a {
        font-family: "CoreSansA45Regular";
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0;
        font-weight: 400;
        color: #188cff;
        text-align: center; }
        html body .content h1.tinyTextTitle-a a, html body .content h2.tinyTextTitle-a a, html body .content h3.tinyTextTitle-a a, html body .content h4.tinyTextTitle-a a, html body .content h5.tinyTextTitle-a a, html body .content h6.tinyTextTitle-a a {
          text-decoration: none;
          color: #215fb5; }
      html body .content h1.mediumTextTitle, html body .content h2.mediumTextTitle, html body .content h3.mediumTextTitle, html body .content h4.mediumTextTitle, html body .content h5.mediumTextTitle, html body .content h6.mediumTextTitle {
        font-family: "CoreSansA45Regular";
        font-size: 22px;
        color: #505050;
        font-weight: 400;
        text-align: left; }
      html body .content h1.largeTextTitle, html body .content h2.largeTextTitle, html body .content h3.largeTextTitle, html body .content h4.largeTextTitle, html body .content h5.largeTextTitle, html body .content h6.largeTextTitle {
        font-family: "CoreSansA15Thin";
        font-size: 40px;
        color: #373737;
        text-transform: capitalize;
        font-weight: 700;
        text-align: center;
        margin: 48px 0 17px;
        justify-content: center; }
        @media (max-width: 1160px) {
          html body .content h1.largeTextTitle, html body .content h2.largeTextTitle, html body .content h3.largeTextTitle, html body .content h4.largeTextTitle, html body .content h5.largeTextTitle, html body .content h6.largeTextTitle {
            line-height: 50px;
            font-size: 30px;
            margin: 20px 0 0;
            padding: 0; } }
        @media (max-width: 450px) {
          html body .content h1.largeTextTitle, html body .content h2.largeTextTitle, html body .content h3.largeTextTitle, html body .content h4.largeTextTitle, html body .content h5.largeTextTitle, html body .content h6.largeTextTitle {
            padding: 0;
            line-height: 34px;
            margin: 20px 0 0;
            font-size: 26px;
            display: flex; } }
      html body .content h1.largeTextTitle-a, html body .content h2.largeTextTitle-a, html body .content h3.largeTextTitle-a, html body .content h4.largeTextTitle-a, html body .content h5.largeTextTitle-a, html body .content h6.largeTextTitle-a {
        font-family: "CoreSansA15Thin";
        font-size: 40px;
        color: #1e71a9;
        text-transform: capitalize;
        font-weight: 700;
        text-align: center;
        margin: 48px 0 17px;
        justify-content: center; }
        @media (max-width: 1160px) {
          html body .content h1.largeTextTitle-a, html body .content h2.largeTextTitle-a, html body .content h3.largeTextTitle-a, html body .content h4.largeTextTitle-a, html body .content h5.largeTextTitle-a, html body .content h6.largeTextTitle-a {
            line-height: 50px;
            font-size: 30px;
            margin: 20px 0 0;
            padding: 0; } }
        @media (max-width: 450px) {
          html body .content h1.largeTextTitle-a, html body .content h2.largeTextTitle-a, html body .content h3.largeTextTitle-a, html body .content h4.largeTextTitle-a, html body .content h5.largeTextTitle-a, html body .content h6.largeTextTitle-a {
            padding: 0;
            line-height: 34px;
            margin: 20px 0 0;
            font-size: 26px;
            display: flex; } }
      html body .content h1.largeTextTitle-m, html body .content h2.largeTextTitle-m, html body .content h3.largeTextTitle-m, html body .content h4.largeTextTitle-m, html body .content h5.largeTextTitle-m, html body .content h6.largeTextTitle-m {
        font-family: "CoreSansA15Thin";
        font-size: 40px;
        color: "";
        text-transform: capitalize;
        font-weight: 700;
        text-align: center;
        margin: 20px 0;
        justify-content: center; }
        @media (max-width: 1160px) {
          html body .content h1.largeTextTitle-m, html body .content h2.largeTextTitle-m, html body .content h3.largeTextTitle-m, html body .content h4.largeTextTitle-m, html body .content h5.largeTextTitle-m, html body .content h6.largeTextTitle-m {
            line-height: 50px;
            font-size: 30px;
            margin: 20px 0 0;
            padding: 0; } }
        @media (max-width: 450px) {
          html body .content h1.largeTextTitle-m, html body .content h2.largeTextTitle-m, html body .content h3.largeTextTitle-m, html body .content h4.largeTextTitle-m, html body .content h5.largeTextTitle-m, html body .content h6.largeTextTitle-m {
            padding: 0;
            line-height: 34px;
            margin: 20px 0 0;
            font-size: 26px;
            display: flex; } }
      html body .content h1.semiLargeTextTitle, html body .content h2.semiLargeTextTitle, html body .content h3.semiLargeTextTitle, html body .content h4.semiLargeTextTitle, html body .content h5.semiLargeTextTitle, html body .content h6.semiLargeTextTitle {
        font-family: "CoreSansA45Regular";
        font-size: 30px;
        font-weight: 500;
        text-align: center;
        color: #505050; }
      html body .content h1.semiLargeTextTitle-a, html body .content h2.semiLargeTextTitle-a, html body .content h3.semiLargeTextTitle-a, html body .content h4.semiLargeTextTitle-a, html body .content h5.semiLargeTextTitle-a, html body .content h6.semiLargeTextTitle-a {
        font-family: "CoreSansA45Regular";
        font-size: 30px;
        font-weight: 500;
        text-align: center;
        color: #188cff; }
      html body .content h1.smallTextTitle, html body .content h2.smallTextTitle, html body .content h3.smallTextTitle, html body .content h4.smallTextTitle, html body .content h5.smallTextTitle, html body .content h6.smallTextTitle {
        font-family: "CoreSansA45Regular";
        font-size: 20px;
        color: #505050;
        font-weight: 700;
        text-align: center;
        margin: 10px 0; }
        html body .content h1.smallTextTitle a, html body .content h2.smallTextTitle a, html body .content h3.smallTextTitle a, html body .content h4.smallTextTitle a, html body .content h5.smallTextTitle a, html body .content h6.smallTextTitle a {
          color: #215fb5 !important;
          transition: color 350ms;
          text-decoration: none; }
          html body .content h1.smallTextTitle a:visited, html body .content h2.smallTextTitle a:visited, html body .content h3.smallTextTitle a:visited, html body .content h4.smallTextTitle a:visited, html body .content h5.smallTextTitle a:visited, html body .content h6.smallTextTitle a:visited {
            color: #19488a; }
          html body .content h1.smallTextTitle a:hover, html body .content h2.smallTextTitle a:hover, html body .content h3.smallTextTitle a:hover, html body .content h4.smallTextTitle a:hover, html body .content h5.smallTextTitle a:hover, html body .content h6.smallTextTitle a:hover {
            color: #3077d9;
            text-decoration: underline; }
          html body .content h1.smallTextTitle a:active, html body .content h2.smallTextTitle a:active, html body .content h3.smallTextTitle a:active, html body .content h4.smallTextTitle a:active, html body .content h5.smallTextTitle a:active, html body .content h6.smallTextTitle a:active {
            color: #11325f; }
      html body .content h1.subTitle, html body .content h2.subTitle, html body .content h3.subTitle, html body .content h4.subTitle, html body .content h5.subTitle, html body .content h6.subTitle {
        font-family: "CoreSansA45Regular";
        font-size: 17px;
        font-weight: 400;
        color: #505050;
        text-align: left;
        margin: "20px 0";
        line-height: 22px; }
        html body .content h1.subTitle a, html body .content h2.subTitle a, html body .content h3.subTitle a, html body .content h4.subTitle a, html body .content h5.subTitle a, html body .content h6.subTitle a {
          color: #215fb5 !important;
          transition: color 350ms;
          text-decoration: none; }
          html body .content h1.subTitle a:visited, html body .content h2.subTitle a:visited, html body .content h3.subTitle a:visited, html body .content h4.subTitle a:visited, html body .content h5.subTitle a:visited, html body .content h6.subTitle a:visited {
            color: #19488a; }
          html body .content h1.subTitle a:hover, html body .content h2.subTitle a:hover, html body .content h3.subTitle a:hover, html body .content h4.subTitle a:hover, html body .content h5.subTitle a:hover, html body .content h6.subTitle a:hover {
            color: #3077d9;
            text-decoration: underline; }
          html body .content h1.subTitle a:active, html body .content h2.subTitle a:active, html body .content h3.subTitle a:active, html body .content h4.subTitle a:active, html body .content h5.subTitle a:active, html body .content h6.subTitle a:active {
            color: #11325f; }
        @media (max-width: 1160px) {
          html body .content h1.subTitle, html body .content h2.subTitle, html body .content h3.subTitle, html body .content h4.subTitle, html body .content h5.subTitle, html body .content h6.subTitle {
            padding: 0 20px;
            font-size: 16px; } }
      html body .content h1.subTitle-m, html body .content h2.subTitle-m, html body .content h3.subTitle-m, html body .content h4.subTitle-m, html body .content h5.subTitle-m, html body .content h6.subTitle-m {
        font-family: "CoreSansA45Regular";
        font-size: 17px;
        font-weight: 400;
        color: #505050;
        text-align: center;
        margin: 0;
        line-height: 22px;
        padding: 10px 0; }
        html body .content h1.subTitle-m a, html body .content h2.subTitle-m a, html body .content h3.subTitle-m a, html body .content h4.subTitle-m a, html body .content h5.subTitle-m a, html body .content h6.subTitle-m a {
          color: #215fb5 !important;
          transition: color 350ms;
          text-decoration: none; }
          html body .content h1.subTitle-m a:visited, html body .content h2.subTitle-m a:visited, html body .content h3.subTitle-m a:visited, html body .content h4.subTitle-m a:visited, html body .content h5.subTitle-m a:visited, html body .content h6.subTitle-m a:visited {
            color: #19488a; }
          html body .content h1.subTitle-m a:hover, html body .content h2.subTitle-m a:hover, html body .content h3.subTitle-m a:hover, html body .content h4.subTitle-m a:hover, html body .content h5.subTitle-m a:hover, html body .content h6.subTitle-m a:hover {
            color: #3077d9;
            text-decoration: underline; }
          html body .content h1.subTitle-m a:active, html body .content h2.subTitle-m a:active, html body .content h3.subTitle-m a:active, html body .content h4.subTitle-m a:active, html body .content h5.subTitle-m a:active, html body .content h6.subTitle-m a:active {
            color: #11325f; }
        @media (max-width: 1160px) {
          html body .content h1.subTitle-m, html body .content h2.subTitle-m, html body .content h3.subTitle-m, html body .content h4.subTitle-m, html body .content h5.subTitle-m, html body .content h6.subTitle-m {
            padding: 0 20px;
            font-size: 16px; } }
      html body .content h1.subTitle-poster, html body .content h2.subTitle-poster, html body .content h3.subTitle-poster, html body .content h4.subTitle-poster, html body .content h5.subTitle-poster, html body .content h6.subTitle-poster {
        font-family: "CoreSansA45Regular";
        font-size: 17px;
        font-weight: 400;
        color: #505050;
        text-align: center;
        margin: 0;
        line-height: 22px;
        padding: 20px !important;
        background-color: #f9fbfc;
        color: #006ea2;
        border: 1px solid #f3f3f3;
        border-radius: 4px; }
        html body .content h1.subTitle-poster a, html body .content h2.subTitle-poster a, html body .content h3.subTitle-poster a, html body .content h4.subTitle-poster a, html body .content h5.subTitle-poster a, html body .content h6.subTitle-poster a {
          color: #215fb5 !important;
          transition: color 350ms;
          text-decoration: none; }
          html body .content h1.subTitle-poster a:visited, html body .content h2.subTitle-poster a:visited, html body .content h3.subTitle-poster a:visited, html body .content h4.subTitle-poster a:visited, html body .content h5.subTitle-poster a:visited, html body .content h6.subTitle-poster a:visited {
            color: #19488a; }
          html body .content h1.subTitle-poster a:hover, html body .content h2.subTitle-poster a:hover, html body .content h3.subTitle-poster a:hover, html body .content h4.subTitle-poster a:hover, html body .content h5.subTitle-poster a:hover, html body .content h6.subTitle-poster a:hover {
            color: #3077d9;
            text-decoration: underline; }
          html body .content h1.subTitle-poster a:active, html body .content h2.subTitle-poster a:active, html body .content h3.subTitle-poster a:active, html body .content h4.subTitle-poster a:active, html body .content h5.subTitle-poster a:active, html body .content h6.subTitle-poster a:active {
            color: #11325f; }
        @media (max-width: 1160px) {
          html body .content h1.subTitle-poster, html body .content h2.subTitle-poster, html body .content h3.subTitle-poster, html body .content h4.subTitle-poster, html body .content h5.subTitle-poster, html body .content h6.subTitle-poster {
            padding: 0 20px;
            font-size: 16px; } }
      html body .content h1.sectionTitle, html body .content h2.sectionTitle, html body .content h3.sectionTitle, html body .content h4.sectionTitle, html body .content h5.sectionTitle, html body .content h6.sectionTitle {
        font-family: "GalanoGrotesque-SemiBold";
        font-size: 16px;
        font-weight: 500;
        color: #404040;
        text-align: center;
        text-transform: uppercase; }
      html body .content h1.sectionText, html body .content h2.sectionText, html body .content h3.sectionText, html body .content h4.sectionText, html body .content h5.sectionText, html body .content h6.sectionText {
        font-family: "CoreSansA45Regular";
        font-size: 15px;
        font-weight: 400;
        color: #505050;
        margin: 5px 0;
        text-align: left; }
      html body .content .pageContainer {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        margin: auto; }
      html body .content i {
        color: #3c86b8; }
    html body .footer {
      align-content: flex-end;
      margin-top: auto; }
